<template>
    <div>
        <div v-if="webauthn && publicKey">
            <WebauthnLogin :remember="true" :public-key="publicKey" @phoneAuth="webauthn = false"/>
        </div>

        <div v-else>
            <PhoneVerificationForm
                v-if="showPhoneVerification"
                :phone="form.phone"
                :channel="verificationChannel"
                @change-number="changeNumber"
            />

            <div v-else>
                <div class="text-center mb-8">
                    <!--                        <span class="block text-sm font-bold uppercase text-indigo-500"></span>-->
                    <h3 class="text-2xl font-bold">Увійди в існуючий акаунт або зареєструйся</h3>
                </div>

                <form @submit.prevent="submit">
                    <div class="mb-5">
                        <PhoneInput
                            class="mt-1 block w-full"
                            v-model="form.phone"
                            autocomplete="webauthn"
                            @validate="checkPhoneNumber"
                        />

                        <InputError class="mt-2" :message="form.errors.phone"/>
                    </div>

                    <!--                            <div class="flex mb-5 items-start">-->
                    <!--                                <label class="text-sm font-bold">-->
                    <!--                                    <Checkbox name="remember" v-model:checked="form.remember"/>-->
                    <!--                                    <span class="mt-1">Запамʼятати мене</span>-->
                    <!--                                </label>-->
                    <!--                            </div>-->

                    <PrimaryButton :loading="form.processing" color="indigo" class="mb-4">
                        Увійти
                    </PrimaryButton>

                    <p class="text-xs text-center">
                        Виконуючи вхід або реєструючись, я приймаю умови<br>
                        <span class="text-indigo-500 font-bold cursor-pointer" @click="showPublicOfferModal = true">угоди користувача</span>
                        та
                        <span class="text-indigo-500 font-bold cursor-pointer" @click="showPrivacyPolicyModal = true">політики конфіденційності</span>
                    </p>

                    <PublicOffer
                        :show="showPublicOfferModal"
                        @close="showPublicOfferModal = false"
                    />
                    <PrivacyPolicy
                        :show="showPrivacyPolicyModal"
                        @close="showPrivacyPolicyModal = false"
                    />

                    <!--                <Link-->
                    <!--                    v-if="canResetPassword"-->
                    <!--                    :href="route('password.request')"-->
                    <!--                    class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"-->
                    <!--                >-->
                    <!--                    Forgot your password?-->
                    <!--                </Link>-->
                    <!--                        <div class="text-center mb-5"><a class="inline-block text-sm font-bold hover:underline" href="#" data-config-id="auto-txt-4-8">Forgot password?</a></div>-->
                    <!--                        <a class="group relative inline-block h-12 mb-2 w-full bg-blueGray-900 rounded-md" href="#">-->
                    <!--                            <div class="absolute top-0 left-0 transform -translate-y-1 -translate-x-1 w-full h-full group-hover:translate-y-0 group-hover:translate-x-0 transition duration-300">-->
                    <!--                                <div class="flex h-full w-full items-center justify-center bg-blue-500 border-2 border-black rounded-md transition duration-300">-->
                    <!--                                    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-1-8">-->
                    <!--                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.13469 17.7272V9.766H8.85806L9.26664 6.66243H6.13469V4.68126C6.13469 3.78299 6.38796 3.17083 7.70273 3.17083L9.37687 3.17015V0.394215C9.08735 0.357312 8.09354 0.272705 6.93684 0.272705C4.52146 0.272705 2.86785 1.71878 2.86785 4.37389V6.66243H0.13623V9.766H2.86785V17.7272H6.13469Z" fill="white"></path>-->
                    <!--                                    </svg>-->
                    <!--                                    <span class="ml-3 text-sm xs:text-base font-black text-white" data-config-id="auto-txt-5-8">Sign In with Facebook</span>-->
                    <!--                                </div>-->
                    <!--                            </div>-->
                    <!--                        </a>-->
                    <!--                        <a class="group relative inline-block h-12 w-full bg-blueGray-900 rounded-md" href="#">-->
                    <!--                            <div class="absolute top-0 left-0 transform -translate-y-1 -translate-x-1 w-full h-full group-hover:translate-y-0 group-hover:translate-x-0 transition duration-300">-->
                    <!--                                <div class="flex h-full w-full items-center justify-center bg-blue-600 border-2 border-black rounded-md transition duration-300">-->
                    <!--                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-config-id="auto-svg-2-8">-->
                    <!--                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.3184 6.14586C21.6358 6.44831 20.9034 6.65343 20.1339 6.74501C20.9196 6.2745 21.5211 5.52819 21.8061 4.64165C21.0691 5.07737 20.2556 5.39377 19.3887 5.56528C18.6946 4.82477 17.7072 4.36353 16.612 4.36353C14.511 4.36353 12.8075 6.06707 12.8075 8.16695C12.8075 8.46477 12.8411 8.75565 12.906 9.0338C9.74459 8.87501 6.94128 7.36037 5.06507 5.05883C4.7371 5.61974 4.55053 6.27331 4.55053 6.97098C4.55053 8.29095 5.22268 9.45562 6.24249 10.137C5.61901 10.1162 5.03262 9.94465 4.51925 9.65956V9.70707C4.51925 11.5497 5.8311 13.0875 7.57056 13.4375C7.25186 13.5233 6.9158 13.5708 6.56813 13.5708C6.32246 13.5708 6.08489 13.5464 5.85195 13.5001C6.33634 15.0124 7.74092 16.1122 9.40507 16.1423C8.10365 17.1621 6.46268 17.7682 4.68034 17.7682C4.37325 17.7682 4.07077 17.7497 3.77295 17.7161C5.4568 18.7973 7.45586 19.4277 9.6044 19.4277C16.6028 19.4277 20.4283 13.631 20.4283 8.60386L20.4155 8.11134C21.163 7.57822 21.8096 6.9084 22.3184 6.14586Z" fill="white"></path>-->
                    <!--                                        <mask id="mask0_1144_2078" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="3" y="4" width="20" height="16">-->
                    <!--                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.3184 6.14586C21.6358 6.44831 20.9034 6.65343 20.1339 6.74501C20.9196 6.2745 21.5211 5.52819 21.8061 4.64165C21.0691 5.07737 20.2556 5.39377 19.3887 5.56528C18.6946 4.82477 17.7072 4.36353 16.612 4.36353C14.511 4.36353 12.8075 6.06707 12.8075 8.16695C12.8075 8.46477 12.8411 8.75565 12.906 9.0338C9.74459 8.87501 6.94128 7.36037 5.06507 5.05883C4.7371 5.61974 4.55053 6.27331 4.55053 6.97098C4.55053 8.29095 5.22268 9.45562 6.24249 10.137C5.61901 10.1162 5.03262 9.94465 4.51925 9.65956V9.70707C4.51925 11.5497 5.8311 13.0875 7.57056 13.4375C7.25186 13.5233 6.9158 13.5708 6.56813 13.5708C6.32246 13.5708 6.08489 13.5464 5.85195 13.5001C6.33634 15.0124 7.74092 16.1122 9.40507 16.1423C8.10365 17.1621 6.46268 17.7682 4.68034 17.7682C4.37325 17.7682 4.07077 17.7497 3.77295 17.7161C5.4568 18.7973 7.45586 19.4277 9.6044 19.4277C16.6028 19.4277 20.4283 13.631 20.4283 8.60386L20.4155 8.11134C21.163 7.57822 21.8096 6.9084 22.3184 6.14586Z" fill="white"></path>-->
                    <!--                                        </mask>-->
                    <!--                                    </svg>-->
                    <!--                                    <span class="ml-3 text-sm xs:text-base font-black text-white" data-config-id="auto-txt-6-8">Sign In with Twitter</span>-->
                    <!--                                </div>-->
                    <!--                            </div>-->
                    <!--                        </a>-->
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import InputError from "@/Components/InputError.vue";
import WebauthnLogin from "@/Pages/Auth/Partials/WebauthnLogin.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import PhoneInput from "@/Components/PhoneInput.vue";
import {browserSupportsWebAuthn} from "@simplewebauthn/browser";
import PrivacyPolicy from "@/Components/Modals/PrivacyPolicy.vue";
import PublicOffer from "@/Components/Modals/PublicOffer.vue";
import {useApiForm} from "@/Helpers/useApiForm/useApiForm.js";
import OtpForm from "@/Components/OtpForm.vue";
import PhoneVerificationForm from "@/Pages/Auth/Partials/PhoneVerificationForm.vue";

export default {
    components: {
        PhoneVerificationForm,
        OtpForm, PublicOffer, PrivacyPolicy, PhoneInput, PrimaryButton, WebauthnLogin, InputError
    },
    props: {
        publicKey: Object,
    },
    data() {
        return {
            form: useApiForm({
                phone: '',
                remember: true,
            }),
            webauthn: browserSupportsWebAuthn(),
            showPublicOfferModal: false,
            showPrivacyPolicyModal: false,

            showPhoneVerification: false,
            verificationChannel: null,
        }
    },
    methods: {
        submit() {
            this.form.post(this.route('login'), {
                onSuccess: (response) => {
                    this.showPhoneVerification = true
                    this.verificationChannel = response.data.channel
                }
            });
        },
        checkPhoneNumber(event) {
            if (event.valid) {
                this.form.phone = event.number

                this.submit()
            }
        },
        changeNumber() {
            this.form.phone = ''

            this.showPhoneVerification = false
        }
    }
}
</script>
